import React from 'react';
import './blonde.css';

const Blonde = () => {

    return(
       <div className='info' style={{'text-align':'center'}}>
           <h1 className='neon' data-text='BLONDE'>BLONDE</h1>
           <h4 className='neon' data-text='NOTAS DE CATA'>NOTAS DE CATA</h4>
           <p>Cerveza dorada de bajo cuerpo con un balanceado aroma a malta y lúpulo. 
           <br/>Se pueden apreciar sabores a pan y bizcocho producto del blend de maltas y cítricos provenientes de los lúpulos utilizados.</p>
           <h4 className='neon' data-text='IMPRESION GENERAL'>IMPRESIÓN GENERAL</h4>
           <p>Estilo de cerveza fabricado con levadura Ale. 
            <br/>Es una cerveza de bajo cuerpo, poco contenido alcohólico, alta tomabilidad y muy refrescante. 
            <br/>Es el estilo ideal para iniciarse en el mundo de las cervezas artesanales y para disfrutar con amigos una tarde de verano.</p>
           <h4 className='neon' data-text='PARA ESTE ESTILO'>PARA ESTE ESTILO USAMOS</h4>
           <p>MALTA PILSEN - MALTA MUNICH<br/>
           LUPULOS: COLUMBUS, CASCADE U.S.A.</p>
           <h4 className='neon' data-text='PARAMETROS VITALES'>PARAMETROS VITALES</h4>
           <p>OG: 1040<br/>FG: 1012<br/>IBU: 15<br/>%ABV: 4,0</p>
       </div>
    )

}

export default Blonde;