import React from 'react';
import blonde from '../../assets/imagenes/bblonde.jpg';
import scottish from '../../assets/imagenes/sscotish.jpg';
import dryStout from '../../assets/imagenes/ddrystout.jpg';
import honey from '../../assets/imagenes/hhoney.jpg';
import americanIpa from '../../assets/imagenes/americanipa.jpg';
import amber from '../../assets/imagenes/amberale.jpg';
import Header from '../../components/header';
import {Link} from 'react-router-dom';
import './home.css'


export default function Home(){


    return(
         <>
         <div className='container'>
          <Header/>
            <div className="row justify-content-center row-cols-md-6 row-cols-sm-2">
                <Link className='navigation' to='blonde'>
                 <div className='card'>
                     <img src={blonde} class="card-img img-fluid" alt="Blonde"/>
                </div>
                </Link>
                <Link className='navigation' to='scottish'>
                <div className='card'>
                     <img src={scottish} class="card-img img-fluid" alt="Scottish"/>
                </div>
                </Link>
                <Link className='navigation' to='stout'>
                <div className='card'>
                     <img src={dryStout} class="card-img img-fluid" alt="Dry Stout"/>
                </div>
                </Link>
                <Link className='navigation' to='honey'>
                <div className='card'>
                     <img src={honey} class="card-img img-fluid" alt="Honey"/>
                </div>
                </Link>
                <Link className='navigation' to='ipa'>
                <div className='card'>
                     <img src={americanIpa} class="card-img img-fluid" alt="American Ipa"/>
                </div>
                </Link>
                <Link className='navigation' to='aaa'>
                <div className='card'>
                     <img src={amber} class="card-img img-fluid" alt="Amber"/>
                </div>
                </Link>
            </div>
        </div>
        </>
    )
}