import React from 'react';
import './honey.css'

const Honey = () => {

    return(
        <div className='info' style={{'text-align':'center'}}>
        <h1 className='neon' data-text='HONEY'>HONEY</h1>
        <h4 className='neon' data-text='NOTAS DE CATA'>NOTAS DE CATA</h4>
        <p>Cerveza dorada de bajo cuerpo y amargor. 
        <br/>Se destaca en nariz y boca un sutil sabor a miel combinado con notas a pan provenientes de la malta.
        </p>
        <h4 className='neon' data-text='HISTORIA'>HISTORIA</h4>
        <p>En la Gran Bretaña medieval, se elaboraban cervezas con miel de diferentes formas. 
         <br/>Algunas eran producto de la fermentación del mosto y miel juntos, en otras se mezclaba una cerveza terminada con hidromiel y también estaban aquellas a las cuales se les agregaba miel sin fermentar junto con alguna que otra especia para darles sabor. 
         <br/>Todas ellas eran conocidas con el nombre de Braggot. Cuando el lúpulo fue aceptado e introducido en las islas no tardó en establecerse como el ingrediente ideal para balancear la dulzura de las braggots. 
         <br/>Las grandes fábricas de cerveza muy pocas veces han usado miel en sus cervezas, pero con la revolución de las microcervecerías ha resurgido este tradicional estilo.
        </p>
        <h4 className='neon' data-text='PARA ESTE ESTILO USAMOS'>PARA ESTE ESTILO USAMOS</h4>
        <p>MALTA PILSEN – MALTA CARAPILS – MIEL (En hervor y fermentación) LÚPULO ZEUS</p>
        <h4 className='PARAMETROS VITALES' data-text='PARAMETROS VITALES'>PARAMETROS VITALES</h4>
        <p>OG: 1060<br/>FG: 1014<br/>IBU: 12<br/>%ABV: 6,5</p>
        </div>
    )

}

export default Honey;