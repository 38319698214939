import React from 'react';
import './stout.css';

const Stout = ()=>{
 
    return(
        <div className='info' style={{'text-align':'center'}}>
        <h1 className='neon' data-text='DRY STOUT'>DRY STOUT</h1>
        <h4 className='neon' data-text='NOTAS DE CATA'>NOTAS DE CATA</h4>
        <p>Cerveza de color negro intenso y espuma cremosa y persistente. Cuerpo medio con sabores tostados. Aroma a cebada tostada similar al café.
        </p>
        <h4 className='neon' data-text='HISTORIA'>HISTORIA</h4>
        <p>Este estilo está muy ligado a las cervezas Porter y algún momento fueron un mismo estilo, incluso se llamaban Stout Porter.        <br/>
        El sistema para clasificar estas cervezas estaba basado en el impuesto que debía pagarse por cada barril de cerveza durante el siglo XIX y principios del XX. 
        <br/>El valor impositivo estaba dado por la densidad inicial de la cerveza. 
        <br/>Una Scotch Ale pagaba entre 90 y 160 chelines, una Scottish Export Ale 80 chelines, una Scottish Heavy Ale 70 chelines y una Scottish Light Ale 60 chelines. 
        <br/>Suele reemplazarse la unidad monetaria chelín por su símbolo /-. Debido a esto se clasifican como 90/-, 80/-, 70/- y 60/-
        <br/><br/>
        Las cervezas Porter se originaron en Londres cerca en el año 1722 y fue uno de los más populares. 
        <br/>Grandes cantidades eran enviadas a Irlanda y a otras regiones europeas.
        Fue en el siglo XVIII que la expresión Stout Porter empezó a utilizarse para referirse a cervezas Porter con mayor contenido alcohólico. 
        <br/>El término Stout significaba -fuerte- y podía relacionarse a cualquier tipo de cerveza, siempre y cuando tuviera un volumen de alcohol mucho más alto que el promedio.
        <br/><br/>
        La popularidad de las Stout Porter (Porter más fuerte) creció de tal manera que, con el paso del tiempo, fue perdiendo la parte de su nombre que llevaba el estilo: Porter. 
        <br/>Así nacieron las cervezas Stout y todas sus variaciones.
        </p>
        <h4 className='neon' data-text='PARA ESTE ESTILO USAMOS'>PARA ESTE ESTILO USAMOS</h4>
        <p>MALTA PALE ALE – ROASTED BARLEY <br/> LUPULO MAGNUM
        </p>
        <h4 className='neon' data-text='PARAMETROS VITALES'>PARAMETROS VITALES</h4>
        <p>OG: 1045<br/>FG: 1012<br/>IBU: 30<br/>%ABV: 4,5</p>
        </div>
    )

}

export default Stout;