import React from 'react';
import './header.css';

const Header = () => {
    return(
        <div style={{
            'paddingTop':'1.8em',
            'paddingBottom':'1em',
            'float':'left'
        }}>  
            <p className='headerP'>Conoce</p>          
            <h1 className='neon' data-text='NUESTROS ESTILOS'>NUESTROS ESTILOS</h1>
        </div>
    )
}

export default Header;