import React from 'react';
import './scottish.css'


const Scottish = () => {
    return(
        <div className='info' style={{'text-align':'center'}}>
        <h1 className='neon' data-text='SCOTTISH EXPORT 80/'>SCOTTISH EXPORT 80/-</h1>
        <h4 className='neon' data-text='NOTAS DE CATA'>NOTAS DE CATA</h4>
        <p>Cerveza de color cobre intenso con un agradable aroma a caramelo. 
        <br/>En boca se destaca el sabor de las maltas caramelo acompañado por suaves notas a lúpulos ingleses.
        </p>
        <h4 className='neon' data-text='HISTORIA'>HISTORIA</h4>
        <p>Los estilos escoceses surgieron a fines del siglo XVIII y actualmente podemos diferenciar cuatro estilos diferentes: 
        <br/>La Scotch Ale, fuertemente alcohólica y tres versiones más livianas denominadas Scottish Light, Heavy y Export.
        <br/>El sistema para clasificar estas cervezas estaba basado en el impuesto que debía pagarse por cada barril de cerveza durante el siglo XIX y principios del XX. El valor impositivo estaba dado por la densidad inicial de la cerveza. 
        <br/>Una Scotch Ale pagaba entre 90 y 160 chelines, una Scottish Export Ale 80 chelines, una Scottish Heavy Ale 70 chelines y una Scottish Light Ale 60 chelines. Suele reemplazarse la unidad monetaria chelín por su símbolo /-. Debido a esto se clasifican como 90/-, 80/-, 70/- y 60/-
        <br/>El término Export proviene de la época de oro de las cervecerías escocesas cuando exportaban prácticamente toda su producción en la época colonial. Ésta se consideraba lo suficientemente potente como para soportar largos viajes en barco sin estropearse. Era un sinónimo de buena calidad. 
        </p>
        <h4 className='neon' data-text='PARA ESTE ESTILO USAMOS'>PARA ESTE ESTILO USAMOS</h4>
        <p>MALTA PALE ALE – CARAMALT – MALTAS CRYSTAL LUPULOS ZEUS
        </p>
        <h4 className='neon' data-text='PARAMETROS VITALES'>PARAMETROS VITALES</h4>
        <p>OG: 1045<br/>FG: 1014<br/>IBU: 16<br/>%ABV: 4,3</p>
        </div>
    )
}

export default Scottish;