import React from 'react';
import './aaa.css';

const Aaa = () => {

    return(
        <div className='info' style={{'text-align':'center'}}>
        <h1 className='neon' data-text='AMERICAN AMBER ALE'>AMERICAN AMBER ALE</h1>
        <h4 className='neon' data-text='NOTAS DE CATA'>NOTAS DE CATA</h4>
        <p>Cerveza de color cobre con una densa y espesa espuma blanca de elevada persistencia. 
        <br/>Logrado balance entre las notas herbales y especiadas del lúpulo y el caramelo de la malta. 
        <br/>De cuerpo medio y chispeante carbonatación.
        </p>
        <h4 className='neon' data-text='IMPRESION GENERAL'>IMPRESION GENERAL</h4>
        <p>La American Amber Ale es una cerveza originada en USA de alto impacto visual debido a su color cobrizo y a su persistente espuma. 
        <br/>También son conocidas como Red Ale. Poseen aroma moderado a lúpulo proveniente del dry hop o de adiciones tardías de lúpulo americano en hervor. 
        <br/>Son cervezas suaves en donde se balancea perfectamente el dulzor de la malta y el amargor del lúpulo.         </p>
        <h4 className='neon' data-text='PARA ESTE ESTILO USAMOS'>PARA ESTE ESTILO USAMOS</h4>
        <p>MALTA PILSEN - MALTAS CRYSTAL - MALTA CARAPILS - MALTA MELANOIDIN <br/>
        LUPULOS: COLUMBUS, EL DORADO (boiler & dry hop)
        </p>
        <h4 className='neon' data-text='PARAMETROS VITALES'>PARAMETROS VITALES</h4>
        <p>OG: 1055<br/>FG: 1010<br/>IBU: 33<br/>%ABV: 5,2</p>
        </div>
    )

}

export default Aaa;