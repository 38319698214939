import React from 'react';
import {NavLink} from 'react-router-dom';
import './navbar.css'

const Navbar = () => {

    return(
        <nav className='navbar navbar-expand-lg navbar-mainbg'>
            <NavLink className='navbar-brand navbar-logo' to='/' exact>
                KALEFF
            </NavLink>
            <button 
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
              >
            <i className='fas fa-bars text-white'></i>
            </button>
            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
                <ul className='navbar-nav ml-auto'>

                    <div className='hori-selector'>
                        <div className='left'></div>
                        <div className='right'></div>
                    </div>

                    <li className='nav-item active'>
                        <NavLink className='nav-link' to='/' exact>
                            <i className='fas fa-beer'></i>
                            Home
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink className='nav-link' to={{ pathname: "https://www.facebook.com/cervezakaleff"}} target="_blank">
                        <i class="fab fa-facebook"></i>
                            Facebook
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink className='nav-link' to={{ pathname: "https://www.instagram.com/cerveza_kaleff/" }} target="_blank" >
                        <i class="fab fa-instagram"></i>
                            Instagram
                        </NavLink>
                    </li>
                   {/*  <li className='nav-item'>
                        <NavLink className='nav-link'>
                            Contacto
                        </NavLink>
                    </li> */}
                </ul>
            
            </div>
        </nav>
    )
}

export default Navbar;