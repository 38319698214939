import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar';
import Home from './views/home';
import Blonde from './views/blonde';
import Honey from './views/honey';
import Scottish from './views/scottish';
import Ipa from './views/ipa';
import Aaa from './views/aaa';
import Stout from './views/stout';

const App = () => {
  return (
      <div className='backgroundStyle'>
      <Navbar/>
      <main>
        <Switch>
          <Route path='/' exact>
            <Home/>
          </Route>
          <Route path='/blonde' exact>
            <Blonde/>
          </Route>
          <Route path='/honey' exact>
            <Honey/>
          </Route>
          <Route path='/scottish' exact>
            <Scottish/>
          </Route>
          <Route path='/ipa' exact>
            <Ipa/>
          </Route>
          <Route path='/aaa' exact>
            <Aaa/>
          </Route>
          <Route path='/stout' exact>
            <Stout/>
          </Route>
        <Redirect to='/'/>
        </Switch>
      </main>
      </div>
  );
}

export default App;
