import React from 'react';
import './ipa.css';

const Ipa = () => {
    return(
        <div className='info' style={{'text-align':'center'}}>
        <h1 className='neon' text-data='AMERICAN IPA'>AMERICAN IPA</h1>
        <h4 className='neon' text-data='NOTAS DE CATA'>NOTAS DE CATA</h4>
        <p>Cerveza dorada de cuerpo medio con agradable sabor y aroma a lúpulos americanos. 
        <br/>Se destacan tanto en nariz como en boca notas cítricas, resinosas y a frutas tropicales provenientes del lúpulo. 
        <br/>Una cerveza decididamente lupulada, compleja y perfectamente balanceada.
        </p>
        <h4 className='neon' data-text='HISTORIA'>HISTORIA</h4>
        <p>Nuestra versión americana del histórico estilo inglés. 
        <br/>Las IPA’s -<b><em>India Pale Ales</em></b>-, nacen en el año 1970 con la necesidad de llevar cerveza desde Gran Bretaña hasta India. 
        <br/>Los barcos iban por zonas cálidas durante mucho tiempo y, como no existía la pasteurización, la única defensa que tenían para evitar que la cerveza se ponga ácida era lupularla fuertemente y aumentar el contenido alcohólico. 
        <br/>El resultado fue una pale ale alcohólica y amarga que fue ganando mercado en India y en el resto del mundo hasta llegar a ser uno de los estilos más populares en la actualidad. 
        </p>
        <h4 className='neon' data-text='PARA ESTE ESTILO USAMOS'>PARA ESTE ESTILO USAMOS</h4>
        <p>MALTA PILSEN - MALTA MUNICH - MALTA CARAPILS<br/>LUPULOS: COLUMBUS, EL DORADO; DRY HOP DE WAIMEA
        </p>
        <h4 className='neon' data-text='PARMETROS VITALES'>PARAMETROS VITALES</h4>
        <p>OG: 1060<br/>FG: 1012<br/>IBU: 55<br/>%ABV: 6,5</p>
        </div>
    )
}

export default Ipa;